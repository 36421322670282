import Vue from "vue";

/*TITLE*/
document.title = "Vivencci Motril | Porcelanosa PARTNERS";
/*PROJECT TYPE*/
Vue.prototype.$typeProject = "PARTNERS";

/*HERO*/
Vue.prototype.$title = "Vivencci Motril";
Vue.prototype.$subtitle = "Diseño e innovación para un nuevo concepto de vivienda";

/*INTRO*/
Vue.prototype.$promoter = "Vivencci Motril";
Vue.prototype.$introSubtitle = "Diseño e innovación para un nuevo concepto de vivienda";

/*GALLERY*/
Vue.prototype.$image_sittingroom = "salon_smart-tanzania-white_22x90_bison--20230116100114.jpg";
Vue.prototype.$image_kitchen = "cocina_op_2_smart-tanzania-white_22x90-zinc--20230116100128.jpg";
Vue.prototype.$image_bath1 = "banyo1_smart-tanzania-white_22x90vela-natural_316x90calacata-gold-nature_mueble-blanco_120x250--20230116100111.jpg";
Vue.prototype.$image_bath2 = "banyo2_smart-tanzania-white_22x90baltimore-natural_333x100countour-natural_333x100--20230116100138.jpg";
Vue.prototype.$image_room = "";
Vue.prototype.$image_facade = "";

Vue.prototype.$gallery_images_sittingroom = [
  {
    src: "salon_smart-tanzania-white_22x90_bison--20230116100114.jpg",
  },
  {
    src: "salon_smart-tanzania-nut_22x90--20230116100117.jpg",
  },
  {
    src: "salon_smart-minnesota-ash_22x90--20230116100120.jpg",
  },
];

Vue.prototype.$gallery_images_kitchen = [
  {
    src: "cocina_op_2_smart-tanzania-white_22x90-zinc--20230116100128.jpg",
  },
  {
    src: "cocina_op_3_smart-minnesota-ash_22x90-roble-organico--20230116100135.jpg",
  },
  {
    src: "cocina_op_2c_smart-tanzania-white_22x90-bison--20230116100140.jpg",
  },
  {
    src: "cocina_op_1_smart-tanzania-nut_22x90-blanco-nubol--20230116100145.jpg",
  },
];

Vue.prototype.$gallery_images_bath1 = [
  {
    src: "banyo1_smart-tanzania-white_22x90vela-natural_316x90calacata-gold-nature_mueble-blanco_120x250--20230116100111.jpg",
  },
  {
    src: "banyo1_smart-tanzania-nut_22x90vela-natural_316x90calacata-gold-nature_mueble-blanco_120x250--20230116100116.jpg",
  },
  {
    src: "banyo1_smart-minnesota-ash_22x90urban-acero-nature_316x90aria-white-nature_mueble-blanco_120x250--20230116100121.jpg",
  },
];

Vue.prototype.$gallery_images_bath2 = [
  {
    src: "banyo2_smart-tanzania-white_22x90baltimore-natural_333x100countour-natural_333x100--20230116100138.jpg",
  },
  {
    src: "banyo2_smart-tanzania-nut_22x90newport-natural_333x100old-natural_333x100--20230116100145.jpg",
  },
  {
    src: "banyo2_smart-tanzania-nut_22x90baltimore-beige_333x100countour-beige_333x100--20230116100149.jpg",
  },
  {
    src: "banyo2_smart-minnesota_22x90baltimore-white_333x100countour-white_333x100--20230116100154.jpg",
  },
];

Vue.prototype.$gallery_images_room = [
];

Vue.prototype.$gallery_images_facade = [
];

/*GALLERY GRID*/
Vue.prototype.$gallery_grid_sittingroom = "6";
Vue.prototype.$gallery_grid_kitchen = "6";
Vue.prototype.$gallery_grid_bath1 = "3";
Vue.prototype.$gallery_grid_bath2 = "3";
Vue.prototype.$gallery_grid_room = "12";
Vue.prototype.$gallery_grid_facade = "12";

/*TOUR VIRTUAL*/
Vue.prototype.$360 = "";

/*3D FILM*/
Vue.prototype.$3dFilm = "";

/*CATALOG*/
Vue.prototype.$catalog = false;

/*SPACES*/
Vue.prototype.$spaces = "";

/*PROMOTION LOGO*/
Vue.prototype.$promotion_logo = true;

/*PROMOTER LOGO*/
Vue.prototype.$promoter_logo = false;

/*CONTACT*/
Vue.prototype.$promoter_address = [
  {
    icon: "mdi-map-marker",
    link: "https://maps.google.com/?q=avenida%20salobreña%20s%20n%20granada",
    text: "Avenida Salobreña S/N, Granada",
  },
];

Vue.prototype.$showroom = [
  {
    icon: "mdi-map-marker",
    link: "https://maps.google.com/?q=edificio%20cdt%20de%20motril%20planta%203ª%20motril%20granada%20",
    text: "Edificio CDT de Motril, Planta 3ª. Motril.(Granada).",
  },
];

Vue.prototype.$contact = [
  {
    icon: "mdi-phone",
    link: "telf:958642442",
    text: "958642442",
  },
  {
    icon: "mdi-email",
    link: "mailto:vivenccimotril@vivencci.es",
    text: "vivenccimotril@vivencci.es",
  },
];
